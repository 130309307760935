<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label>Mã thiết bị<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="code"
              rules="required"
              :custom-messages="code"
            >
              <b-form-input
                v-model="dataInput.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Tên thiết bị<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="name"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Ngày kiểm định<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="dateTimeTest"
              rules="required"
              :custom-messages="dateTimeTest"
            >
              <date-time-picker
                v-model="dataInput.dateTimeTest"
                :disabledInput="true"
                :class="{'is-invalid':errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Đặc tính kỹ thuật<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="content"
              rules="required"
              :custom-messages="content"
            >
              <BFormTextarea
                v-model="dataInput.content"
                :class="{'is-invalid':errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      code: {
        required: 'Mã thiết bị là bắt buộc',
      },
      name: {
        required: 'Tên thiết bị là bắt buộc',
      },
      dateTimeTest: {
        required: 'Ngày kiểm định là bắt buộc',
      },
      content: {
        required: 'Đặc tính kỹ thuật là bắt buộc',
      },
      dataInput: {
        code: '',
        name: '',
        dateTimeTest: null,
        content: '',
      },

    }
  },
  watch: {
    dataDetail() {
      if (this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetail.code,
          name: this.dataDetail.name,
          dateTimeTest: this.dataDetail.dateTimeTest,
          content: this.dataDetail.content,
        }
      }
    },
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        code: '',
        name: '',
        dateTimeTest: null,
        content: '',
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
