var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"cancel-variant":"outline-primary","ok-title":"Lưu","cancel-title":"Hủy bỏ","centered":"","no-close-on-backdrop":"","title":_vm.title},on:{"ok":function($event){return _vm.accept($event)},"hidden":_vm.resetData}},[_c('validation-observer',{ref:"rule"},[_c('b-form',[_c('b-form-group',[_c('label',[_vm._v("Mã thiết bị"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"code","rules":"required","custom-messages":_vm.code},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.code),callback:function ($$v) {_vm.$set(_vm.dataInput, "code", $$v)},expression:"dataInput.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Tên thiết bị"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.name),callback:function ($$v) {_vm.$set(_vm.dataInput, "name", $$v)},expression:"dataInput.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Ngày kiểm định"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"dateTimeTest","rules":"required","custom-messages":_vm.dateTimeTest},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{class:{'is-invalid':errors.length > 0 },attrs:{"disabledInput":true},model:{value:(_vm.dataInput.dateTimeTest),callback:function ($$v) {_vm.$set(_vm.dataInput, "dateTimeTest", $$v)},expression:"dataInput.dateTimeTest"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Đặc tính kỹ thuật"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"content","rules":"required","custom-messages":_vm.content},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormTextarea',{class:{'is-invalid':errors.length > 0 },model:{value:(_vm.dataInput.content),callback:function ($$v) {_vm.$set(_vm.dataInput, "content", $$v)},expression:"dataInput.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }